import React from 'react'
import { navigate } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { GET_SCHOOL_LIST } from './school-queries'
import { DataCols } from '@clubspark-react/clubspark-react-tools'
import Button from 'src/components/button/button'
import Panel from 'src/components/panel/panel'
import Icon from 'src/components/icon/icon'
import * as styles from './schools.module.less'
import { TeamTypeEnum } from 'src/utils/typedefs/division-and-regions'
import { initQueryFilterTableHook } from '../../hooks/use-query-filter-table'
import { Spacing } from '../../hooks/spacing'
import { meshGatewayClient } from '../../apollo/client'
import { useSchoolFilterOptions } from './school-helpers'

/** TODO: replace with the graph-ql types once the apollo-typegen starts working */
interface School {
  id: string
  name: string
  conference?: string
  itaRegion?: string
  rankingAwardRegion?: string
  ustaSection?: string
  state?: string
  manId?: string
  womanId?: string
  teamType?: TeamTypeEnum
  division?: string
}

type SchoolAdminColumnData = DataCols<School>

const useQFT = initQueryFilterTableHook<any, School>(GET_SCHOOL_LIST)

const filterSpacing: Spacing = {
  base: 6,
  margins: {
    xs: ['bottom', 'right']
  }
}

const accessoriesBarSpacing: Spacing = {
  base: 5,
  margins: {
    xs: 'top',
    md: 'bottom'
  }
}

const useSchoolAdminColumns: () => SchoolAdminColumnData = () => {
  const { t } = useTranslation()
  const { t: divisionTranslation } = useTranslation(['divisions'])
  const translateDivision = (value?: string) => (value ? divisionTranslation(value) : 'xxx')

  const cols: SchoolAdminColumnData = [
    {
      key: 'name',
      title: t('name'),
      getValue: (school: School) => <span className={styles.colorLabel}>{school.name}</span>
    },
    {
      key: 'division',
      title: t('division'),
      getValue: (school: School) => translateDivision(school.division)
    },
    {
      key: 'itaRegion',
      title: t('region'),
      getValue: (school: School) => translateDivision(school.itaRegion)
    },
    {
      key: 'rankingAwardRegion',
      title: t('ranking award region'),
      getValue: (school: School) => translateDivision(school.rankingAwardRegion)
    },
    {
      key: 'conference',
      title: t('conference'),
      getValue: (school: School) => translateDivision(school.conference)
    },
    {
      key: 'team',
      title: t('team'),
      getValue: (school: School) => {
        if (school.teamType === TeamTypeEnum.Men_And_Women) {
          return t('both teams')
        }

        if (school.teamType === TeamTypeEnum.Men) {
          return t('mens team')
        }

        if (school.teamType === TeamTypeEnum.Women) {
          return t('womens team')
        }

        return 'xxx'
      }
    }
  ]

  return cols
}

const Schools = () => {
  const adminColumns = useSchoolAdminColumns()
  const { t } = useTranslation()
  const {
    divisionOptions,
    playingRegionOptions,
    rankingAwardRegionOptions,
    conferenceOptions,
    teamOptions
  } = useSchoolFilterOptions()

  const getQueryVarsFromContext = ctx => {
    const { division, name, rankingAwardRegion, itaRegion, conference, teamType } = ctx.filters
    const { limit, offset } = ctx.paging

    const filter = {
      ...(name ? { nameRegexp: name } : undefined),
      ...(division ? { division: division } : {}),
      ...(itaRegion ? { itaRegion: itaRegion } : {}),
      ...(rankingAwardRegion ? { rankingAwardRegion: rankingAwardRegion } : {}),
      ...(conference ? { conference: conference } : {}),
      ...(teamType ? { teamType: teamType } : {})
    }

    return {
      skip: offset,
      limit,
      totalFilter: filter,
      filter
    }
  }

  const {
    components: { FilterBar, AdminTable, props }
  } = useQFT(
    {
      name: {
        position: 1,
        type: 'search',
        initialValue: '',
        props: {
          onClear: ctx => {
            ctx.setValues({
              [ctx.filterKey]: ''
            })
          },
          placeholder: t('name'),
          delay: 400
        },
        spacing: filterSpacing
      },
      division: {
        position: 2,
        type: 'select',
        initialValue: '',
        props: {
          options: divisionOptions
        },
        spacing: filterSpacing
      },
      itaRegion: {
        position: 3,
        type: 'select',
        initialValue: '',
        props: {
          options: playingRegionOptions
        },
        spacing: filterSpacing
      },
      rankingAwardRegion: {
        position: 4,
        type: 'select',
        initialValue: '',
        props: {
          options: rankingAwardRegionOptions
        },
        spacing: filterSpacing
      },
      conference: {
        position: 5,
        type: 'select',
        initialValue: '',
        props: {
          options: conferenceOptions
        },
        spacing: filterSpacing
      },
      teamType: {
        position: 6,
        type: 'select',
        initialValue: '',
        props: {
          options: teamOptions
        },
        spacing: filterSpacing
      }
    },
    {
      columns: adminColumns,
      accessoryBar: {
        items: [],
        gap: 12,
        spacing: accessoriesBarSpacing
      }
    },
    {
      columnSelectorId: 'schools',
      urlParams: {},
      mapFiltersToQueryOptions: ({ filters, helper }) => {
        return {
          client: meshGatewayClient,
          getTotalItems: d => d?.schoolTotalCount || 0,
          transformVariables: (vars: { limit?: number; offset?: number }) => {
            const { limit, offset } = vars
            return getQueryVarsFromContext({
              filters,
              paging: { limit, offset },
              helper
            })
          },
          fetchPolicy: 'no-cache'
        }
      },
      mapDataToTable: data => data?.schoolMany as School[],
      depsTableConfig: []
    }
  )

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <h1 className={styles.heading}>Schools</h1>
        </Grid>
        <Grid>
          <Button spacing={{ margins: { auto: 'left' } }} onClick={() => navigate('/schools/add')}>
            <Icon name="sm-add" className={styles.addIcon} /> Add School
          </Button>
        </Grid>
      </Grid>
      <Panel>
        <FilterBar {...props.filterBar} />
        <AdminTable
          {...props.adminTable}
          hideTopPaginationInfo
          onRowClick={school => navigate(`/schools/${school.id}/edit`)}
        />
      </Panel>
    </>
  )
}

export default Schools
