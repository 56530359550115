import { useTranslation } from 'react-i18next'
import { Option } from '../mui-select/mui-select'
import {
  ConferenceByDivision,
  DivisionEnum,
  RankingAwardByDivision,
  RegionByDivision,
  TeamTypeEnum
} from '../../utils/typedefs/division-and-regions'

const useEnumToSelectOptions = () => {
  const { t } = useTranslation(['divisions'])

  return (input: any): Option[] =>
    Object.values(input)
      .flat()
      .reduce((prev: string[], curr) => (prev.includes(curr) ? prev : [...prev, curr]), [])
      .map(
        (key: string): Option => ({
          label: t(key),
          value: key
        })
      )
}

export const useSchoolFilterOptions = () => {
  const { t } = useTranslation(['translation'])
  const enumToSelectOptions = useEnumToSelectOptions()

  const divisionOptions: Option[] = [
    {
      label: t('division'),
      value: ''
    },
    ...enumToSelectOptions(DivisionEnum)
  ]

  const playingRegionOptions = [
    {
      label: t('playing region'),
      value: ''
    },
    ...enumToSelectOptions(RegionByDivision)
  ]

  const rankingAwardRegionOptions: Option[] = [
    {
      label: t('ranking award region'),
      value: ''
    },
    ...enumToSelectOptions(RankingAwardByDivision)
  ]

  const conferenceOptions: Option[] = [
    {
      label: t('conference'),
      value: ''
    },
    ...enumToSelectOptions(ConferenceByDivision)
  ]

  const teamOptions: Option[] = [
    {
      label: t('team type'),
      value: ''
    },
    ...enumToSelectOptions(TeamTypeEnum)
  ]

  return {
    divisionOptions,
    playingRegionOptions,
    rankingAwardRegionOptions,
    conferenceOptions,
    teamOptions
  }
}
